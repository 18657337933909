// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$telepagos-typography: mat.define-typography-config(
  $font-family: 'Poppins',
);

@include mat.core($telepagos-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$telepagos-primary: (
 50: var(--primary),
 100: var(--primary),
 200: var(--primary),
 300: var(--primary),
 400: var(--primary),
 500: var(--primary),
 600: var(--primary),
 700: var(--primary),
 800: var(--primary),
 900: var(--primary),

 // ... continues to 900
 contrast: (
   50: rgba(white, 0.87),
   100: rgba(white, 0.87),
   200: rgba(white, 0.87),
   300: rgba(white, 0.87),
   400: rgba(white, 0.87),
   500: rgba(white, 0.87),
   600: rgba(white, 0.87),
   700: rgba(white, 0.87),
   800: rgba(white, 0.87),
   900: rgba(white, 0.87),
   // ... continues to 900
 )
);   

$telepagos-accent: (
 50: var(--tertiary),
 100: var(--tertiary),
 200: var(--tertiary),
 300: var(--tertiary),
 400: var(--tertiary),
 500: var(--tertiary),
 600: var(--tertiary),
 700: var(--tertiary),
 800: var(--tertiary),
 900: var(--tertiary),

 // ... continues to 900
 contrast: (
   50: rgba(white, 0.87),
   100: rgba(white, 0.87),
   200: rgba(white, 0.87),
   300: rgba(white, 0.87),
   400: rgba(white, 0.87),
   500: rgba(white, 0.87),
   600: rgba(white, 0.87),
   700: rgba(white, 0.87),
   800: rgba(white, 0.87),
   900: rgba(white, 0.87),
   // ... continues to 900
 )
);   

$telepagos-admin-primary: mat.define-palette($telepagos-primary);
$telepagos-admin-accent: mat.define-palette($telepagos-accent);

// The warn palette is optional (defaults to red).
$telepagos-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".


$telepagos-admin-theme: mat.define-light-theme((
  color: (
    primary: $telepagos-admin-primary,
    accent: $telepagos-admin-accent,
    warn: $telepagos-admin-warn,
  ),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($telepagos-admin-theme);

/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

// @import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
// @import "~bootstrap/dist/css/bootstrap.min.css";
// @import '~admin-lte/dist/css/adminlte.css';

@import '~ngx-toastr/toastr';

/*
Los colores del brand estan como 'primary' , 'primary-dark' , 'secondary' ,'tertiary'
*/ 
:root {
    --blue: #0077ba;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #f29525;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #0077ba;
    --primary-light: #00a2ff45;
    --primary-extraLight: #00a2ff30;
    --primary-dark: #004b93;
    --secondary: #6c757d;
    --tertiary: #f29525;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #f29525;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Poppins" , "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

body {
    width: 100vw;
    height: 100vh;
    font-family: "Poppins" , "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.user-menu > .nav-link {
    cursor: pointer;
}

.user-menu > .nav-link:after {
    content: none;
}

.user-menu > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
    border-bottom: 1px solid #495057;
    border-top: 1px solid #dee2e6;
    padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
    display: block;
    clear: both;
    content: '';
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-body a {
        background: #ffffff !important;
        color: #495057 !important;
    }
}

.user-menu > .dropdown-menu > .user-footer {
    background-color: #f8f9fa;
    padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
    display: block;
    clear: both;
    content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #6c757d;
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f8f9fa;
    }
}

.user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2.1rem;
    margin-right: 10px;
    margin-top: -2px;
    width: 2.1rem;
}

@media (min-width: 576px) {
    .user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-right: 0.4rem;
        margin-top: -8px;
    }
}

.user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.content {
    padding: 0 0.5rem;
}


.sidebar-collapse  {
    
    .brand-image-large {
        display: none ;
    }
    .angle{
        display: none ;
    }
}

.sidebar-open  {

    .brand-image-small{
        display: none ;
    }
    
}
.main-sidebar:hover {

    .brand-image-large{
        display: inline ;
    }

    .brand-image-small{
        display: none ;
    }

    .angle{
        display: inline ;
    }
    
   
}


//Para corregir template

// .main-sidebar {

//     width: 350px !important;
// }

.navbar-dark{
    border-width: 0 !important;
}

.bg-body{
    background-color : #343a40 ;
}

.alert{
    border-radius: 1rem;
}

.nav-treeview .nav-link{
    background-color: rgba(255, 255, 255, 0.1);
}


//Para proposito general

.cursor-pointer{
    cursor: pointer;
}

.back-btn{
    display: inline-block;
    vertical-align: middle;
    color: var(--secondary);
    margin-right: 1rem;
    cursor: pointer;
}

.toast-warning-custom{
    background-color: #F89406;
}

.text-monospace{
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
}

//scrollbar

.overflow-scroll {
    overflow: scroll !important;

    &::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    border-radius: .5rem;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar
    {
    width: .7rem;
    height: .7rem;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar-thumb
    {
    border-radius: .5rem;
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    background-color: #555;
    }
}
.overflow-scroll-y {
    overflow-y: scroll !important;

    &::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    border-radius: .5rem;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar
    {
    width: .7rem;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar-thumb
    {
    border-radius: .5rem;
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    background-color: #555;
    }
}
.overflow-scroll-x {
    overflow-x: scroll !important;

    &::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    border-radius: .5rem;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar
    {
    height: .7rem;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar-thumb
    {
    border-radius: .5rem;
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    background-color: #555;
    }
}

body {

    &::-webkit-scrollbar-track
    {
    -webkit-box-shadow: inset 0 0 .5rem rgba(0,0,0,0.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
    
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar
    {
    width: .5rem;
    height: 0;
    background-color: #f4f6f9;
    }

    &::-webkit-scrollbar-thumb
    {
    border-radius: .5rem;
    -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
    background-color: #555;
    }
}


// botones block

@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;

    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}

// Estilos app telepagos

.card , .small-box {
    border-radius: 1.2rem;
}

.btn {
    border-radius: 1rem;
}

.dropdown-menu  {
    border-radius: 1.2rem !important; 

    li:first-child{
        border-radius: 1.2rem 1.2rem 0 0 !important; 
    }
    li:last-child{
        border-radius: 0 0 1.2rem 1.2rem !important; 
    }
    
}

.custom-dialog{
    mat-dialog-container{
        border-radius: 1.2rem;
    }
}


//Fix generales del layout

table {
    font-size: 0.9rem
}

.cdk-overlay-container {
    z-index: 9999 !important;
}

//Tipografias

@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Thin.ttf) format("truetype");;
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");;
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Light.ttf) format("truetype");;
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");;
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");;
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");;
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");;
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");;
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Black.ttf) format("truetype");;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-ThinItalic.ttf) format("truetype");;
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf) format("truetype");;
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-LightItalic.ttf) format("truetype");;
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-Italic.ttf) format("truetype");;
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-MediumItalic.ttf) format("truetype");;
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format("truetype");;
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-BoldItalic.ttf) format("truetype");;
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");;
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Poppins";
    src: url(./assets/fonts/Poppins/Poppins-BlackItalic.ttf) format("truetype");;
    font-weight: 900;
    font-style: italic;
}



